class Nav
	constructor: ->
		@$nav = $ '#menu'
		@initNav()

	initNav: ->
		@$nav.dropit()

#######################

class Slider
	constructor: ->
		@$slider = $ '#slider'
		@$controls = $ '.slider-controls'
		@initSlider()
		@bindEvents()

	initSlider: ->
		@sliderApi = @$slider.bxSlider
			infiniteLoop: false
			hideControlOnEnd: true
			pagerCustom: '.slider-pager'
			slideWidth: 682
			controls: false

	bindEvents: ->
		@$controls.find('.prev').on 'click', @prevSlide
		@$controls.find('.next').on 'click', @nextSlide

	prevSlide: (event) =>
		event.preventDefault()
		@sliderApi.goToPrevSlide()

	nextSlide: (event) =>
		event.preventDefault()
		@sliderApi.goToNextSlide()

#######################

class Forms
	constructor: ->
		@$selects = $ '.js-custom-select'
		@initSelect2()

	initSelect2: ->
		@$selects.select2(
			minimumResultsForSearch: -1
		)

#######################

Nav.window = Nav
Slider.window = Slider
Forms.window = Forms

$(document).ready ->
	new Nav()
	new Slider()
	new Forms()
